import React from 'react';
import Logo from 'src/components/Logo';
import styled from 'styled-components';
import Link from './Link';

interface ErrorPageProps {
  message?: string;
  code?: string | number;
  linkMessage?: string;
  linkUrl?: string;
}

export default function ErrorPage({
  message,
  code,
  linkMessage,
  linkUrl,
}: ErrorPageProps) {
  return (
    <ErrorPageCss>
      <ErrorPageHeader>
        <Logo />
      </ErrorPageHeader>
      <ErrorPageContentCss>
        {code && <ErrorPageCodeCss>{code}</ErrorPageCodeCss>}
        {message && <ErrorPageMessageCss>{message}</ErrorPageMessageCss>}
        {linkMessage && linkUrl && (
          <Link href={linkUrl}>
            <LinkMessageCss>{linkMessage} </LinkMessageCss>
          </Link>
        )}
      </ErrorPageContentCss>
    </ErrorPageCss>
  );
}

const ErrorPageCss = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorPageHeader = styled.header`
  padding: ${p => p.theme.sizes.spacing10};
`;

const ErrorPageContentCss = styled.div`
  width: 100%;
  max-width: 600px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
  justify-content: space-between;
  & > div:not(:last-child),
  span:not(:last-child) {
    margin-bottom: ${p => p.theme.sizes.spacing6};
  }
`;

const LinkMessageCss = styled.span`
  ${p => p.theme.getFontStyle('primary', 'condensedBold')}
  color: ${p => p.theme.colors.primary500};
`;

const ErrorPageCodeCss = styled.span`
  ${p => p.theme.getFontStyle('primary', 'condensedBold')}
  color: white;
  background: ${p => p.theme.colors.black};
  padding: ${p => p.theme.sizes.spacing4};
  border-radius: ${p => p.theme.sizes.spacing2};
  font-size: ${p => p.theme.typography.textHuge};
`;

const ErrorPageMessageCss = styled.span`
  ${p => p.theme.getFontStyle('primary', 'condensedBold')}
  color: ${p => p.theme.colors.black};
  font-size: ${p => p.theme.typography.text2Xl};
`;
